import _ from 'lodash';
import { getVODApiUrl } from '@wix/wix-vod-shared/dist/src/common/settings/get-vod-api-url';
import { isSiteBased } from '@wix/wix-vod-shared/dist/src/common/settings/settings';
import { service as widgetsService } from '@wix/wix-vod-api/dist/src/private/widgets/widgets';
import { service as settingsService } from '@wix/wix-vod-api/dist/src/private/settings/settings';
var SITE_BASED_URL = '//vod.wix.com/api/v2/';
export var SERVICES = [widgetsService, settingsService];
var setBaseUrl = function (baseUrl) {
    _.forEach(SERVICES, function (service) {
        var axiosInstance = service.getAxiosInstance
            ? service.getAxiosInstance()
            : service;
        axiosInstance.defaults.baseURL = getVODApiUrl(baseUrl);
    });
};
export var setSiteBasedUrls = function () {
    if (isSiteBased()) {
        setBaseUrl(SITE_BASED_URL);
    }
};
