import { __assign, __awaiter, __generator, __read, __spread } from "tslib";
import _ from 'lodash';
import axios from 'axios';
import { REFRESH_EVENT } from 'widget/utils/reload';
import EVENTS from 'shared/constants/events';
import { BI_EVENT } from 'shared/bi/constants';
import { restoreAction, createEventPublisher, } from './lib';
import { createMemoryHistory } from 'history';
import WixEvents from 'shared/constants/wix-sdk';
import { createAndSetupMonitor } from 'loggers/sentry';
import { setInitialAppSettings } from 'widget/redux/client/actions/app-settings';
import { setHydratedDataFromSource } from 'widget/redux/client/hydrated-data/hydrated-data';
import { fetchVODExperiments } from 'api/public/experiments/experiments';
import { fetchTranslations } from 'api/public/translations';
// import { ALL_PROXIES } from './registerProxies';
import { SET_PUBLIC_PATH } from 'services/locale/config';
import { getBiToken } from 'shared/worker/getBiToken';
import DEVICE_TYPES from 'shared/constants/device-types';
import { VIDEO_APP_DEF_ID } from '@wix/wix-vod-constants/dist/common/app-def-id';
import { configureAxios, createClient } from 'shared/configure-axios';
import { getVideosCount } from 'shared/worker/getVideosCount';
import { getCurrentSiteUser } from 'shared/worker/helpers/current-site-user';
import { experimentsStore } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
function wrapError(fn, errHandler) {
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var res;
        try {
            res = fn.apply(void 0, __spread(args));
        }
        catch (err) {
            return errHandler(err);
        }
        if (res && typeof res === 'object' && res.then && res.catch) {
            return res.catch(errHandler);
        }
        return res;
    };
}
function getFromGlobal() {
    return _.pick(global, [
        '__LOCALE__',
        '__TRANSLATIONS__',
        '__SINGLE_VIDEO__',
        '__VIDEOS__',
        '__MEMBER_INFO__',
        'viewMode',
        '__CURRENT_SITE_USER__',
        'biToken',
        '__EXPERIMENTS__',
        '__MIGRATION_INFO__',
        'windowSize',
        '__SITE_URL__',
        '__EDITOR_TYPE__',
        '__CHANNEL__',
        'instance',
        'instanceId',
        'compId',
        'deviceType',
    ]);
}
function createControllerWithErrorLogging(createController, monitor) {
    function onError(err) {
        monitor.captureException(err);
        if (__DEV__) {
            console.error(err);
        }
        throw err;
    }
    return createController()
        .then(function (controller) {
        return {
            pageReady: wrapError(controller.pageReady, onError),
        };
    })
        .catch(onError);
}
function getInitialPubSubEvents() {
    var _a;
    return _a = {},
        _a[REFRESH_EVENT] = [],
        _a[EVENTS.SESSION.LOGOUT_USER] = null,
        _a[EVENTS.PAYMENT.CANCEL_SUBSCRIPTION] = null,
        _a[BI_EVENT] = null,
        _a;
}
export function subscribeToEvents(_a) {
    var setProps = _a.setProps, pubSub = _a.pubSub;
    var pubSubEvents = getInitialPubSubEvents();
    var debouncedSetProps = _.debounce(setProps, 100);
    var handlePubSubEventSeries = function (eventName, isPersistent) {
        pubSub.subscribe(eventName, function (data) {
            pubSubEvents[eventName] = __spread(pubSubEvents[eventName], [data]).slice(-10);
            debouncedSetProps({ pubSubEvents: __assign({}, pubSubEvents) });
        }, isPersistent);
    };
    var handlePubSubEvent = function (eventName, isPersistent) {
        pubSub.subscribe(eventName, function (data) {
            pubSubEvents[eventName] = data;
            debouncedSetProps({ pubSubEvents: __assign({}, pubSubEvents) });
        }, isPersistent);
    };
    var handleEvent = function (eventName) {
        if (global.Wix) {
            global.Wix.addEventListener(eventName, function (data) {
                pubSubEvents[eventName] = data;
                debouncedSetProps({ pubSubEvents: __assign({}, pubSubEvents) });
            });
        }
    };
    var subscribe = function () {
        handlePubSubEventSeries(REFRESH_EVENT, true);
        handlePubSubEvent(EVENTS.SESSION.LOGIN_USER);
        handlePubSubEvent(EVENTS.SESSION.LOGOUT_USER);
        handlePubSubEvent(EVENTS.PAYMENT.CANCEL_SUBSCRIPTION);
        handlePubSubEvent(EVENTS.PAYMENT.PAYMENT_EVENT);
        handlePubSubEvent(BI_EVENT);
        handleEvent(WixEvents.PAGE_NAVIGATION);
        handleEvent(WixEvents.SETTINGS_UPDATED);
        handleEvent(WixEvents.SITE_PUBLISHED);
        handleEvent(WixEvents.EDIT_MODE_CHANGE);
        handleEvent(WixEvents.STYLE_PARAMS_CHANGE);
    };
    subscribe();
}
function patchControllerConfig(controllerConfig, _a) {
    var getEvents = _a.getEvents;
    var pubSubEvents = getInitialPubSubEvents();
    var newSetProps = function (props) {
        controllerConfig.setProps(__assign({ events: getEvents(), pubSubEvents: pubSubEvents }, props));
    };
    return __assign(__assign({}, controllerConfig), { setProps: newSetProps });
}
export var getSiteLocale = function (_a) {
    var wixCodeApi = _a.wixCodeApi, styleParams = _a.styleParams;
    var window = wixCodeApi.window, site = wixCodeApi.site;
    var multilingual = window.multilingual;
    if (multilingual.isEnabled) {
        return multilingual.currentLanguage;
    }
    if (styleParams && styleParams.fonts && styleParams.fonts.language) {
        return styleParams.fonts.language;
    }
    return site.language;
};
export function createViewerScript(_a, _b) {
    var createWorkerHandlerFromConfig = _a.createWorkerHandlerFromConfig;
    var getStoreBuilder = _b.getStoreBuilder, _c = _b.isSlave, isSlave = _c === void 0 ? false : _c;
    function getPageReadyData(_a) {
        var wixCodeApi = _a.wixCodeApi;
        var currentPageId = wixCodeApi.site.currentPage.id;
        return { currentPageId: currentPageId };
    }
    function getHydratedSource(_a) {
        var wixCodeApi = _a.wixCodeApi, platformAPIs = _a.platformAPIs, appParams = _a.appParams, compId = _a.compId, config = _a.config;
        return __awaiter(this, void 0, void 0, function () {
            var viewMode, siteOwnerId, instanceId, instance, __SITE_URL__, __CURRENT_SITE_USER__, styleParams, siteLocale, fullSiteUrl, deviceType, biToken, metaSiteId, templateMetaSiteId, widgetData, _b, baseUrl, protocol, domainWithProtocol, videosCount, data, e_1, experimentsPromise, translationsPromise, _c, __EXPERIMENTS__, __TRANSLATIONS__;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        viewMode = wixCodeApi.window.viewMode.toLowerCase();
                        siteOwnerId = platformAPIs.bi.ownerId;
                        instanceId = appParams.instanceId, instance = appParams.instance;
                        __SITE_URL__ = wixCodeApi.location.baseUrl;
                        return [4 /*yield*/, getCurrentSiteUser(wixCodeApi.user.currentUser)];
                    case 1:
                        __CURRENT_SITE_USER__ = _d.sent();
                        styleParams = config.style.styleParams;
                        siteLocale = getSiteLocale({ wixCodeApi: wixCodeApi, styleParams: styleParams });
                        fullSiteUrl = wixCodeApi.location.url;
                        deviceType = wixCodeApi.window.formFactor.toLowerCase();
                        biToken = getBiToken(instance, platformAPIs);
                        metaSiteId = platformAPIs.bi.metaSiteId;
                        templateMetaSiteId = platformAPIs.bi.templateMetaSiteId;
                        widgetData = {};
                        if (!__OOI__) return [3 /*break*/, 5];
                        _b = wixCodeApi.location, baseUrl = _b.baseUrl, protocol = _b.protocol;
                        domainWithProtocol = protocol + "://" + baseUrl.split('/')[2];
                        videosCount = getVideosCount({
                            isMobile: deviceType === DEVICE_TYPES.MOBILE,
                            styleParams: styleParams,
                        });
                        _d.label = 2;
                    case 2:
                        _d.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, axios.get(domainWithProtocol + "/wix-vod-server/widget-data", {
                                params: _.omitBy({
                                    channelId: styleParams.fonts.channelId,
                                    videoId: styleParams.fonts.videoId,
                                    instance: instance,
                                    locale: siteLocale,
                                    videosCount: videosCount,
                                }, _.isUndefined),
                            })];
                    case 3:
                        data = (_d.sent()).data;
                        widgetData = data;
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _d.sent();
                        console.log(e_1);
                        return [3 /*break*/, 5];
                    case 5:
                        experimentsPromise = (function () { return __awaiter(_this, void 0, void 0, function () {
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        _a = global.__EXPERIMENTS__ ||
                                            widgetData.__EXPERIMENTS__;
                                        if (_a) return [3 /*break*/, 2];
                                        return [4 /*yield*/, fetchVODExperiments()];
                                    case 1:
                                        _a = (_b.sent());
                                        _b.label = 2;
                                    case 2: return [2 /*return*/, _a];
                                }
                            });
                        }); })();
                        translationsPromise = (function () { return __awaiter(_this, void 0, void 0, function () {
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        _a = global.__TRANSLATIONS__ ||
                                            widgetData.__TRANSLATIONS__;
                                        if (_a) return [3 /*break*/, 2];
                                        return [4 /*yield*/, fetchTranslations(siteLocale)];
                                    case 1:
                                        _a = (_b.sent());
                                        _b.label = 2;
                                    case 2: return [2 /*return*/, _a];
                                }
                            });
                        }); })();
                        return [4 /*yield*/, Promise.all([
                                experimentsPromise,
                                translationsPromise,
                            ])];
                    case 6:
                        _c = __read.apply(void 0, [_d.sent(), 2]), __EXPERIMENTS__ = _c[0], __TRANSLATIONS__ = _c[1];
                        experimentsStore.set(__EXPERIMENTS__);
                        return [2 /*return*/, {
                                siteOwnerId: siteOwnerId,
                                instanceId: instanceId,
                                instance: instance,
                                viewMode: viewMode,
                                appSettings: styleParams,
                                compId: compId,
                                metaSiteId: metaSiteId,
                                templateMetaSiteId: templateMetaSiteId,
                                widgetData: _.omit(widgetData, ['__EXPERIMENTS__', '__TRANSLATIONS__']),
                                __SITE_URL__: __SITE_URL__,
                                __CURRENT_SITE_USER__: __CURRENT_SITE_USER__,
                                __EXPERIMENTS__: __EXPERIMENTS__,
                                __TRANSLATIONS__: __TRANSLATIONS__,
                                siteLocale: siteLocale,
                                fullSiteUrl: fullSiteUrl,
                                deviceType: deviceType,
                                biToken: biToken,
                            }];
                }
            });
        });
    }
    function createStore(_a) {
        var controllerConfig = _a.controllerConfig, setProps = _a.setProps, publishEvent = _a.publishEvent, performanceLogger = _a.performanceLogger, initialData = _a.initialData;
        return __awaiter(this, void 0, void 0, function () {
            var wixCodeApi, platformAPIs, url, instance, configData, _b, client, setInstance, onInstanceChanged, middleWares, history, _c, origCreateStore, setInitialState, store;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        wixCodeApi = controllerConfig.wixCodeApi, platformAPIs = controllerConfig.platformAPIs;
                        url = '/';
                        instance = initialData.instance;
                        configData = { instance: instance, url: url };
                        _b = createClient(), client = _b.client, setInstance = _b.setInstance;
                        onInstanceChanged = function (event) {
                            var newConfigData = __assign(__assign({}, configData), { instance: event.instance });
                            configureAxios(configData);
                            setInstance(event.instance);
                            setProps({ configData: newConfigData });
                        };
                        wixCodeApi.site.onInstanceChanged(onInstanceChanged);
                        middleWares = [
                        // createWorkerHandlerMiddleware(workerHandler),
                        // createProxiesMiddleware(ALL_PROXIES),c
                        ];
                        history = createMemoryHistory({ initialEntries: [url] });
                        _c = getStoreBuilder({
                            isMobile: initialData.deviceType === DEVICE_TYPES.MOBILE,
                        }), origCreateStore = _c.createStore, setInitialState = _c.setInitialState;
                        store = origCreateStore({
                            client: client,
                            middleWares: middleWares,
                            history: history,
                        });
                        configureAxios(configData);
                        setInstance(configData.instance);
                        store.dispatch(setHydratedDataFromSource(initialData));
                        store.dispatch(setInitialAppSettings(initialData.appSettings));
                        if (!setInitialState) return [3 /*break*/, 2];
                        return [4 /*yield*/, store.dispatch(setInitialState())];
                    case 1:
                        _d.sent();
                        _d.label = 2;
                    case 2: 
                    // const dispatchEv = action =>
                    //   store.dispatch(restoreAction({ publishEvent, action }));
                    // store.subscribe(() => {
                    //   setProps({
                    //     appState: store.getState(),
                    //   });
                    // });
                    return [2 /*return*/, {
                            configData: configData,
                            appState: store.getState(),
                        }];
                }
            });
        });
    }
    function createAppController(controllerConfig, _a) {
        var monitor = _a.monitor;
        return __awaiter(this, void 0, void 0, function () {
            var wixCodeApi, setProps, platformAPIs, _b, publishEvent, getEvents, consumeEvents, pendingCurrentSiteUser, handleLogin, _c, _hydratedSource, appId, widgetId, performanceLogger, captureException;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        wixCodeApi = controllerConfig.wixCodeApi, setProps = controllerConfig.setProps, platformAPIs = controllerConfig.platformAPIs;
                        _b = createEventPublisher({
                            setProps: controllerConfig.setProps,
                        }), publishEvent = _b.publishEvent, getEvents = _b.getEvents, consumeEvents = _b.consumeEvents;
                        handleLogin = function (event) { return __awaiter(_this, void 0, void 0, function () {
                            var currentSiteUser;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, getCurrentSiteUser(event)];
                                    case 1:
                                        currentSiteUser = _a.sent();
                                        platformAPIs.pubSub.publish(EVENTS.SESSION.LOGIN_USER, currentSiteUser);
                                        pendingCurrentSiteUser = currentSiteUser;
                                        return [2 /*return*/];
                                }
                            });
                        }); };
                        wixCodeApi.user.onLogin(handleLogin);
                        controllerConfig = patchControllerConfig(controllerConfig, { getEvents: getEvents });
                        return [4 /*yield*/, Promise.all([
                                getHydratedSource(controllerConfig),
                            ])];
                    case 1:
                        _c = __read.apply(void 0, [_d.sent(), 1]), _hydratedSource = _c[0];
                        appId = VIDEO_APP_DEF_ID;
                        widgetId = '144097ea-fea0-498e-ade7-e6de40127106';
                        if (__OOI__) {
                            performanceLogger = platformAPIs.fedOpsLoggerFactory.getLoggerForWidget({
                                appId: appId,
                                widgetId: widgetId,
                            });
                        }
                        else {
                            performanceLogger = platformAPIs.fedOpsLoggerFactory;
                        }
                        captureException = function (err, opts) { return monitor.captureException(err, opts); };
                        if (__OOI__) {
                            performanceLogger.appLoadStarted();
                        }
                        return [2 /*return*/, {
                                pageReady: function () {
                                    return __awaiter(this, void 0, void 0, function () {
                                        var pageReadyData, initialData, workerHandler, dispatchEv, storeProps, componentProps;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0:
                                                    pageReadyData = getPageReadyData(controllerConfig);
                                                    initialData = __assign(__assign(__assign({}, getFromGlobal()), _hydratedSource), pageReadyData);
                                                    workerHandler = createWorkerHandlerFromConfig(controllerConfig, {
                                                        performanceLogger: performanceLogger,
                                                        isSlave: isSlave,
                                                    });
                                                    dispatchEv = function (action) {
                                                        return workerHandler(restoreAction({ publishEvent: publishEvent, action: action }));
                                                    };
                                                    if (pendingCurrentSiteUser) {
                                                        initialData.__CURRENT_SITE_USER__ = pendingCurrentSiteUser;
                                                    }
                                                    return [4 /*yield*/, createStore({
                                                            controllerConfig: controllerConfig,
                                                            setProps: setProps,
                                                            // publishEvent, // we cant publish events before initial setProps (check on invalid video id from url)
                                                            performanceLogger: performanceLogger,
                                                            initialData: initialData,
                                                        })];
                                                case 1:
                                                    storeProps = _a.sent();
                                                    componentProps = __assign(__assign({}, storeProps), { dispatchEv: dispatchEv,
                                                        consumeEvents: consumeEvents,
                                                        captureException: captureException, renderingEnv: wixCodeApi.window.rendering.env, experiments: initialData.__EXPERIMENTS__ });
                                                    setProps(componentProps);
                                                    // we cant publish events before initial setProps (check on invalid video id from url)
                                                    subscribeToEvents({
                                                        setProps: setProps,
                                                        pubSub: platformAPIs.pubSub,
                                                    });
                                                    if (wixCodeApi.window.rendering.env === 'backend') {
                                                        performanceLogger.appLoaded();
                                                    }
                                                    return [2 /*return*/, componentProps.appState];
                                            }
                                        });
                                    });
                                },
                            }];
                }
            });
        });
    }
    var createControllers = function (controllerConfigs) {
        if (__DEBUG__) {
            console.log('creating controllers', controllerConfigs);
        }
        var publicPathOverride = controllerConfigs[0].wixCodeApi.location.query.publicPathOverride;
        if (publicPathOverride) {
            SET_PUBLIC_PATH(publicPathOverride);
            console.log("overriding public path with " + publicPathOverride);
        }
        return controllerConfigs.map(function (config) {
            var monitor = createAndSetupMonitor(config.platformAPIs.monitoring.createMonitor, config);
            return createControllerWithErrorLogging(function () { return createAppController(config, { monitor: monitor }); }, monitor);
        });
    };
    return {
        createControllers: createControllers,
    };
}
