import { __read, __spread } from "tslib";
import Lodash from 'lodash';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import biMiddleware from 'shared/bi/bi-middleware';
import lazyChannelVideosMiddleware from 'widget/redux/middleware/lazy-channel-videos/lazy-channel-videos-middleware';
import biHandlers from 'widget/redux/middleware/bi-middleware/bi-handlers';
import { initNotForPreview } from 'shared/utils/not-for-preview';
export var createConfigureStore = function (_a) {
    var createRootReducer = _a.createRootReducer, createRootReducerPath = _a.createRootReducerPath;
    return function (_a) {
        var _b = _a.initialState, initialState = _b === void 0 ? {} : _b, _c = _a.middlewares, additionalMiddlewares = _c === void 0 ? [] : _c, history = _a.history, client = _a.client;
        var createService = function (serviceCls) { return new serviceCls(client); };
        var storage = {};
        var middlewares = [
            thunk.withExtraArgument({ createService: createService, storage: storage }),
            routerMiddleware(history),
            lazyChannelVideosMiddleware,
            biMiddleware(biHandlers),
        ];
        if (__DEBUG__ && !__SERVER__) {
            var reduxLogger = require('redux-logger').createLogger({
                collapsed: true,
                diff: true,
            });
            middlewares.push(reduxLogger);
        }
        middlewares.push.apply(middlewares, __spread(additionalMiddlewares));
        var middleware = !__SERVER__
            ? require('redux-devtools-extension').composeWithDevTools(applyMiddleware.apply(void 0, __spread(middlewares)))
            : applyMiddleware.apply(void 0, __spread(middlewares));
        // Create final store and subscribe router in debug env ie. for devtools
        var store = middleware(createStore)(createRootReducer({ history: history }), Lodash.omit(initialState, 'router'));
        if (module.hot) {
            module.hot.accept(createRootReducerPath, function () {
                var createNextRootReducer = require(createRootReducerPath).default;
                store.replaceReducer(createNextRootReducer({ history: history }));
            });
        }
        initNotForPreview(store);
        return store;
    };
};
