import { __read, __spread } from "tslib";
import _ from 'lodash';
import { guid } from '@wix/wix-vod-shared/dist/src/common/utils/guid';
var CorrelatedBiEventsChain = /** @class */ (function () {
    function CorrelatedBiEventsChain(resetEventName) {
        var eventNames = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            eventNames[_i - 1] = arguments[_i];
        }
        this.eventNames = __spread([resetEventName], eventNames);
        this.resetEventName = resetEventName;
        this.correlationId = guid();
    }
    CorrelatedBiEventsChain.prototype.updateCorrelationId = function () {
        this.correlationId = guid();
    };
    CorrelatedBiEventsChain.prototype.isSupportedEvent = function (eventName) {
        return _.includes(this.eventNames, eventName);
    };
    CorrelatedBiEventsChain.prototype.isResetEvent = function (eventName) {
        return eventName === this.resetEventName;
    };
    CorrelatedBiEventsChain.prototype.getCorrelationId = function (eventName) {
        if (!this.isSupportedEvent(eventName)) {
            return null;
        }
        if (this.isResetEvent(eventName)) {
            this.updateCorrelationId();
        }
        return this.correlationId;
    };
    return CorrelatedBiEventsChain;
}());
export default CorrelatedBiEventsChain;
